






















































































































































import { defineComponent } from '@nuxtjs/composition-api'

// Types
import { PageContentTilesBlock } from '~/types/cms/page'

export default defineComponent({
  components: {},
  props: {
    tiles: {
      type: Object as () => PageContentTilesBlock,
      required: true,
    },
  },
})
