



















































































import { defineComponent, useMeta } from '@nuxtjs/composition-api'
import { PageContentNavigationBlock } from '~/types/cms/page'
export default defineComponent({
  props: {
    navigation: {
      type: Object as () => PageContentNavigationBlock['navigation'],
      required: true,
    },
  },

  setup() {
    useMeta(() => ({
      script: [
        {
          src: 'https://fast.wistia.com/assets/external/E-v1.js',
          body: true,
          async: true,
        },
        {
          src: 'https://fast.wistia.com/embed/medias/epsiladdg7.jsonp',
          body: true,
          async: true,
        },
      ],
    }))
  },

  head: {},
})
