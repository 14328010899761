
















import { defineComponent } from '@nuxtjs/composition-api'

// Components
import HeroSectionVariantB from './variant_b_herobanner.vue'
import HomepageVideoVariantB from './variant_b_video.vue'
import HomepageTilesVariantB from './variant_b_tiles.vue'

export default defineComponent({
  name: 'HomepageB',
  components: {
    HeroSectionVariantB,
    HomepageVideoVariantB,
    HomepageTilesVariantB,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
